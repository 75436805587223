<!--
 * @Author: PoJun
 * @Date: 2023-08-08 10:48:34
 * @LastEditors: PoJun
 * @LastEditTime: 2023-08-17 14:49:35
 * @Message: Nothing
-->
<template>
    <div class="contact">
        <!-- contact us -->
        <div class="contact-us">
            <div class="container" v-viewer>
                <div class="row">
                    <div class="img-box col-md-6">
                        <img src="../../assets/img/position.png" alt="" />
                    </div>

                    <div class="col-md-6">
                        <div class="entry">
                            <h5>Mr.杨</h5>
                            <ul>
                                <li>
                                    <i class="fas fa-phone-alt"></i>
                                    181 1161 8069
                                </li>
                                <li>
                                    <i class="far fa-envelope"></i>
                                    king@kingtomtech.com
                                </li>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    中国（四川）自由贸易试验区成都高新区世纪城路198号附4233号3层A区01号
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end contact us -->
    </div>
</template>

<script>
export default {
    name: "contact",
    mounted() {},
};
</script>

<style scoped>
.contact-us {
    margin: 40px 0;
}
.contact {
    flex: 1;
}

.img-box {
    margin-bottom: 40px;
    cursor: pointer;
}

.entry li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
